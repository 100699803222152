import styled from "styled-components";

const Layout = styled.div`
  width: calc(100vw - 20px);
  max-width: 900px;
  height: calc(100vh - 20px);

  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;

  background: #f7f3e8;
  border-radius: 100px;

  display: flex;
  flex-direction: column;
`;

export default Layout;
