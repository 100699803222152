import React, { useContext, useState, useReducer } from "react";
import styled from "styled-components";
import PageHeader from "./PageHeader";
import HemisphereContext from "../contexts/HemisphereContext";
import Table, { stringSort, numberSort } from "../Table";
import Input from "../Input";
import MonthPicker from "../MonthPicker";

import data from "../../data/fish.json";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PageContent = styled.div`
  overflow: auto;
`;

const PageHeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;

  & > * {
    flex: 1;
    margin: 0 10px;
  }

  & > :nth-child(2) {
    flex: 2;
  }
`;

const monthFilterReducer = (state, newMonth) => {
  if (state.includes(newMonth)) {
    return state.filter(m => m !== newMonth);
  } else {
    return [...state, newMonth];
  }
};

const FishPage = () => {
  const { hemisphere } = useContext(HemisphereContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [monthFilter, monthFilterDispatch] = useReducer(monthFilterReducer, []);

  const columns = [
    { key: "Id", header: () => "#", sort: numberSort },
    {
      key: "Fish",
      header: () => "Fish",
      sort: stringSort,
      filter: value => value.toLowerCase().includes(searchTerm.toLowerCase())
    },
    { key: "Location", header: () => "Location", sort: stringSort },
    { key: "Size", header: () => "Size", sort: stringSort },
    {
      key: "Value",
      header: () => "Value",
      render: (value, row) => row.DisplayValue,
      sort: numberSort
    },
    { key: "Time", header: () => "Time", sort: stringSort },
    {
      key: "Month",
      header: () => "Month",
      render: value => value[hemisphere].DisplayValue,
      filter: value => !monthFilter.length || value[hemisphere].Value.some(month => monthFilter.includes(month))
    }
  ];

  return (
    <Page>
      <PageHeader>Fish</PageHeader>
      <PageHeaderBar>
        <Input
          type="text"
          placeholder="search..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.currentTarget.value)}
        />
        <MonthPicker months={monthFilter} onChange={monthFilterDispatch} />
      </PageHeaderBar>

      <PageContent>
        <Table data={data} columns={columns} />

        {/* <Table>
          <thead>
            <tr>
              <th>
                <ClickableTableHeader onClick={() => sortDispatch("Id")}>#</ClickableTableHeader>
              </th>
              <th>
                <ClickableTableHeader onClick={() => sortDispatch("Fish")}>Fish</ClickableTableHeader>
              </th>
              <th>
                <ClickableTableHeader onClick={() => sortDispatch("Location")}>Location</ClickableTableHeader>
              </th>
              <th>
                <ClickableTableHeader onClick={() => sortDispatch("Size")}>Size</ClickableTableHeader>
              </th>
              <th>
                <ClickableTableHeader onClick={() => sortDispatch("Value")}>Value</ClickableTableHeader>
              </th>
              <th>
                <ClickableTableHeader onClick={() => sortDispatch("Time")}>Time</ClickableTableHeader>
              </th>
              <th>Month</th>
            </tr>
          </thead>
          <tbody>
            {data
              .filter(fish => {
                if (!searchTerm) return true;

                return (
                  fish.Fish.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  fish.Location.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  fish.Size.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  fish.Value.toString().includes(searchTerm) ||
                  fish.DisplayValue.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  fish.Time.toLowerCase().includes(searchTerm.toLowerCase())
                );
              })
              .filter(fish => {
                if (monthFilter.length < 1) return true;

                return fish.Month[hemisphere].Value.filter(m => monthFilter.includes(m)).length > 0;
              })
              .sort((a, b) =>
                currentSort.column === "Month"
                  ? sortByColumn(
                      a[currentSort.column][hemisphere].Value,
                      b[currentSort.column][hemisphere].Value,
                      currentSort.direction
                    )
                  : sortByColumn(a[currentSort.column], b[currentSort.column], currentSort.direction)
              )
              .map(fish => (
                <tr key={fish.Id}>
                  <td>{fish.Id}</td>
                  <td>{fish.Fish}</td>
                  <td>{fish.Location}</td>
                  <td>{fish.Size}</td>
                  <td>{fish.DisplayValue}</td>
                  <td>{fish.Time}</td>
                  <td>{fish.Month[hemisphere].DisplayValue}</td>
                </tr>
              ))}
          </tbody>
        </Table> */}
      </PageContent>
    </Page>
  );
};

export default FishPage;
