import styled from "styled-components";

const Input = styled.input`
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.8);
  padding: 0.4rem 0.9rem;
  font-size: 0.9rem;
  font-weight: bold;
  width: 100%;

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
`;

export default Input;
