import React, { useReducer } from "react";
import styled from "styled-components";
import classNames from "classnames";

const TableStyle = styled.table`
  border: 0;
  border-collapse: collapse;
  width: 100%;

  & thead > tr > th {
    font-size: 1.1rem;
    padding-bottom: 0.5rem;
  }

  & tbody > tr:nth-child(2n) {
    background: rgba(0, 0, 0, 0.05);
  }

  & td {
    padding: 5px 10px;
  }
`;

const SortHeaderButton = styled.button`
  font-size: 100%;
  font-family: inherit;
  font-weight: inherit;
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  position: relative;

  &.active::after {
    position: absolute;
    margin-left: 0.2rem;
    content: "${props => (props.direction === "asc" ? " ▴" : " ▾")}";
  }
`;

export const stringSort = (a, b) => a.localeCompare(b);
export const numberSort = (a, b) => a - b;

const Table = ({ data, columns }) => {
  const initialSortState = { column: null, direction: "asc" };

  const sortReducer = (state, column) => {
    let direction = "asc";

    if (column === state.column) {
      if (state.direction === "asc") {
        direction = "desc";
      } else {
        // TODO: third click should return to default sort?
        direction = "asc";
      }
    }

    return { column, direction };
  };

  const [currentSort, sortDispatch] = useReducer(sortReducer, initialSortState);

  // apply filters
  for (const column of columns) {
    if (column.filter === undefined) {
      continue;
    }

    data = data.filter(row => column.filter(row[column.key]));
  }

  // apply sort
  if (currentSort.column !== null) {
    data = data.sort((a, b) => {
      const col = columns.filter(c => c.key === currentSort.column)[0];

      if (currentSort.direction === "asc") return col.sort(a[currentSort.column], b[currentSort.column]);
      else return col.sort(b[currentSort.column], a[currentSort.column]);
    });
  }

  return (
    <TableStyle>
      <thead>
        <tr>
          {columns.map(column => (
            <th key={column.key}>
              {column.sort !== undefined ? (
                <SortHeaderButton
                  onClick={() => sortDispatch(column.key)}
                  className={classNames({ active: currentSort.column === column.key })}
                  direction={currentSort.direction}
                >
                  {column.header()}
                </SortHeaderButton>
              ) : (
                column.header()
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => (
          <tr key={i}>
            {columns.map(column => (
              <td key={column.key}>
                {column.render !== undefined ? column.render(row[column.key], row) : row[column.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  );
};

export default Table;
