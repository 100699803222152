import React, { useContext, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import HemisphereContext, { hemispheres } from "./contexts/HemisphereContext";
import icon from "../icons/hemisphere.svg";
import useInterval from "../hooks/useInterval";

const Style = styled.header`
  width: 100%;
  height: 30px;
  padding: 10px;
  color: rgba(0, 0, 0, 0.15);
  font-weight: bold;
  font-size: 1.1rem;

  display: flex;
  justify-content: space-around;

  & > * {
    flex: 1;
  }
`;

const HemisphereToggle = styled.button`
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  color: inherit;
  font-size: inherit;
  font-weight: inherit;
`;

const HemisphereIcon = styled.img`
  width: 1.1rem;
  height: 1.1rem;
  transform: rotate(${props => (props.hemisphere === hemispheres.northern ? "0deg" : "180deg")});
  opacity: 0.15;
  margin: 5px 0;
`;

const HeaderBar = () => {
  const getTime = () => moment().format("hh:mm A");

  const { hemisphere, toggleHemisphere } = useContext(HemisphereContext);
  const [time, setTime] = useState(getTime());

  useInterval(() => {
    setTime(getTime());
  }, 60 * 1000);

  return (
    <Style>
      <span />
      <span>{time}</span>
      <HemisphereToggle onClick={toggleHemisphere}>
        <HemisphereIcon src={icon} hemisphere={hemisphere} />
      </HemisphereToggle>
    </Style>
  );
};

export default HeaderBar;
