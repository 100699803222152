import React, { useContext, useState, useReducer } from "react";
import styled from "styled-components";
import PageHeader from "./PageHeader";
import HemisphereContext from "../contexts/HemisphereContext";
import Table, { stringSort, numberSort } from "../Table";
import Input from "../Input";

import data from "../../data/bugs.json";
import MonthPicker from "../MonthPicker";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PageContent = styled.div`
  overflow: auto;
`;

const PageHeaderBar = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 10px;

  & > * {
    flex: 1;
    margin: 0 10px;
  }
`;

const monthFilterReducer = (state, newMonth) => {
  if (state.includes(newMonth)) {
    return state.filter(m => m !== newMonth);
  } else {
    return [...state, newMonth];
  }
};

const BugsPage = () => {
  const { hemisphere } = useContext(HemisphereContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [monthFilter, monthFilterDispatch] = useReducer(monthFilterReducer, []);

  const columns = [
    { key: "Id", header: () => "#", sort: numberSort },
    {
      key: "Bug",
      header: () => "Bug",
      sort: stringSort,
      filter: value => value.toLowerCase().includes(searchTerm.toLowerCase())
    },
    { key: "Location", header: () => "Location", sort: stringSort },
    { key: "Value", header: () => "Value", render: (value, row) => row.DisplayValue, sort: numberSort },
    { key: "Time", header: () => "Time", sort: stringSort },
    {
      key: "Month",
      header: () => "Month",
      render: value => value[hemisphere].DisplayValue,
      filter: value => !monthFilter.length || value[hemisphere].Value.some(month => monthFilter.includes(month))
    }
  ];

  return (
    <Page>
      <PageHeader>Bugs</PageHeader>
      <PageHeaderBar>
        <Input
          type="text"
          placeholder="search..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.currentTarget.value)}
        />
        <MonthPicker months={monthFilter} onChange={monthFilterDispatch} />
      </PageHeaderBar>

      <PageContent>
        <Table data={data} columns={columns} />
      </PageContent>
    </Page>
  );
};

export default BugsPage;
