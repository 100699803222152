import React from "react";
import monthData from "../data/months.json";
import styled from "styled-components";

const MonthButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`;

const MonthButton = styled.button`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-size: 0.7rem;
  font-weight: bold;
  padding: 0.3rem;
  background: rgba(0, 0, 0, 0.2);
  border: 0;
  opacity: 0.4;
  cursor: pointer;

  & + * {
    margin-left: 4px;
  }

  &.active {
    opacity: 1;
    color: #f7f3e8;
  }
`;

const MonthPicker = ({ months, onChange }) => {
  return (
    <MonthButtonContainer>
      {monthData.map(m => (
        <MonthButton key={m} className={months.includes(m) ? "active" : ""} onClick={() => onChange(m)}>
          {m.substring(0, 3)}
        </MonthButton>
      ))}
    </MonthButtonContainer>
  );
};

export default MonthPicker;
