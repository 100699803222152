import React from "react";

export const hemispheres = {
  northern: "northern",
  southern: "southern"
};

const HemisphereContext = React.createContext({
  hemisphere: hemispheres.northern,
  toggleHemisphere: () => {}
});

export default HemisphereContext;
