import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PageHeader from "./PageHeader";

const AppContainer = styled.div`
  /* border: 1px solid black; */
  display: flex;
  flex-wrap: wrap;
  /* margin: 0 50px; */
  justify-content: space-evenly;

  & > * {
    margin-right: 20px;
  }
`;

const AppLink = styled(Link)`
  width: 120px;
  height: 120px;
  background: palevioletred;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const HomePage = () => {
  return (
    <div>
      <PageHeader>Critterpedia</PageHeader>
      <AppContainer>
        <AppLink to="/fish">fish</AppLink>
        <AppLink to="/bugs">bugs</AppLink>
        <AppLink to="/shells">shells</AppLink>
      </AppContainer>
    </div>
  );
};

export default HomePage;
