import React, { useState } from "react";
import Layout from "./components/Layout";
import { Switch, Route } from "react-router-dom";
import { HomePage, FourOhFour, FishPage, BugsPage, ShellsPage } from "./components/pages";
import HeaderBar from "./components/HeaderBar";
import HemisphereContext, { hemispheres } from "./components/contexts/HemisphereContext";
import styled from "styled-components";

import "./App.css";

const Footer = styled.footer`
  height: 100px;
`;

const PageContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

const App = () => {
  const [hemisphere, setHemisphere] = useState(hemispheres.northern);

  const toggleHemisphere = () => {
    setHemisphere(hemisphere === hemispheres.northern ? hemispheres.southern : hemispheres.northern);
  };

  return (
    <HemisphereContext.Provider value={{ hemisphere, toggleHemisphere }}>
      <div className="App">
        <Layout>
          <HeaderBar />
          <PageContainer>
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route path="/fish">
                <FishPage />
              </Route>
              <Route path="/bugs">
                <BugsPage />
              </Route>
              <Route path="/shells">
                <ShellsPage />
              </Route>
              <Route>
                <FourOhFour />
              </Route>
            </Switch>
          </PageContainer>
          <Footer></Footer>
        </Layout>
      </div>
    </HemisphereContext.Provider>
  );
};

export default App;
